<div id="wrapper">
    <div id="message">
        <p>
            👋 Hello {{ this.userFullName() }}! Here is your Data Sources Status
        </p>
    </div>
    <div id="update_status">
        <div id="status_header">
            <h1>Data Update Status</h1>
            <div id="live_status">
                <img src="assets/icons/live_status.svg" />
                <p>Live</p>
            </div>
        </div>
        <div id="status_container">
            <div class="updates_status">
                <div class="header">
                    <img src="assets/icons/data_library.svg" />
                    <h2>Data Library</h2>
                </div>
                <div class="states_container">
                    <div class="states">
                        <h3>Created</h3>
                        <p>Past 30 days</p>
                        <a [routerLink]="['/', 'created']"
                            >{{ this.data_library_kpi.created }} Sources</a
                        >
                    </div>
                    <div class="states">
                        <h3>Activated</h3>
                        <p>Past 30 days</p>
                        <a [routerLink]="['/', 'activated']"
                            >{{ this.data_library_kpi.activated }} Sources</a
                        >
                    </div>
                    <div class="states">
                        <h3>Deactivated</h3>
                        <p>Past 30 days</p>
                        <a [routerLink]="['/', 'deactivated']"
                            >{{ this.data_library_kpi.deactivated }} Sources</a
                        >
                    </div>
                </div>
            </div>
            <div class="updates_status" style="flex: 0.6">
                <div class="header">
                    <img src="assets/icons/data_library.svg" />
                    <h2>Data Delivery</h2>
                </div>
                <div class="states_container">
                    <div class="states">
                        <h3>Awaiting</h3>
                        <p>Next 7 days</p>
                        <a [routerLink]="['/', 'awaiting_delivery']"
                            >{{
                                this.data_delivery_kpi.awaiting_delivery
                            }}
                            Sources</a
                        >
                    </div>
                    <div class="states">
                        <h3>Delayed</h3>
                        <p>More than 2 days</p>
                        <a [routerLink]="['/', 'delayed']"
                            >{{
                                this.data_delivery_kpi.delayed_sources
                            }}
                            Sources</a
                        >
                    </div>
                </div>
            </div>
            <div class="updates_status">
                <div class="header">
                    <img src="assets/icons/data_library.svg" />
                    <h2>Data Update</h2>
                </div>
                <div class="states_container">
                    <div class="states">
                        <h3>Pending</h3>
                        <p>-</p>
                        <a [routerLink]="['/', 'pending']"
                            >{{ this.dataUpdateMetrics["pending"] }} Sources</a
                        >
                    </div>
                    <div class="states">
                        <h3>In Progress</h3>
                        <p>-</p>
                        <!--@TODO fix this eventually (object key)-->
                        <a [routerLink]="['/', 'in_progress']"
                            >{{
                                this.dataUpdateMetrics["in-progess"]
                            }}
                            Sources</a
                        >
                    </div>
                    <div class="states">
                        <h3>Completed</h3>
                        <p>-</p>
                        <a [routerLink]="['/', 'completed']"
                            >{{
                                this.dataUpdateMetrics["completed"]
                            }}
                            Sources</a
                        >
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="current_update">
        <h1>Current Update</h1>
        <div class="update">
            <h2>Update #{{ last_completed_batch_id }}</h2>
            <div class="update_details">
                <h3>Completed</h3>
                <div class="steps_container">
                    <app-steps-component
                        [stepsInfo]="this.step_info"
                    ></app-steps-component>
                </div>
                <p-table
                    [value]="table_data"
                    styleClass="p-datatable-gridlines"
                    [scrollable]="true"
                >
                    <ng-template pTemplate="header">
                        <tr>
                            <th></th>
                            <th>New Records</th>
                            <th>Current Records</th>
                            <th>Unique New Values</th>
                            <th>Unique Values</th>
                            <th>New Undefined Values</th>
                            <th>Undefined Values</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" let-item>
                        <tr>
                            <td>{{ this.capitalize(item.batch_step) }}</td>
                            <td>{{ item.new_records }}</td>
                            <td>{{ item.current_records }}</td>
                            <td>{{ item.unique_new_values }}</td>
                            <td>{{ item.unique_values }}</td>
                            <td>{{ item.total_new_blank_values }}</td>
                            <td>{{ item.total_blank_values }}</td>
                        </tr>
                    </ng-template>
                </p-table>
            </div>
        </div>
    </div>
    <div id="notification_center">
        <div class="header">
            <h1>Notification Center</h1>
            <p-dropdown
                [options]="this.time_durations"
                [(ngModel)]="notifications_time_filter"
                (onChange)="notificationFilterChanged()"
            ></p-dropdown>
        </div>

        <div id="notifications_container">
            <div
                class="notification"
                [class.inactive_notification]="!notification.active"
                *ngFor="
                    let notification of notifications
                        | paginateNotifications
                            : this.currentPageNumber
                            : this.perPageNotifications
                "
            >
                <img
                    src="assets/icons/{{
                        notification.active ? 'blue_dot' : 'grey_dot'
                    }}.svg"
                />
                <img
                    *ngIf="
                        notification.user_id === null ||
                        notification.user_id === ''
                    "
                    src="assets/img/avatar.png"
                    class="avatar"
                />
                <img
                    *ngIf="notification.user_id && notification.user_id !== ''"
                    [src]="notification.user_profile_picture"
                    class="avatar"
                />

                <div style="min-width: 220px">
                    <ng-container
                        *ngIf="
                            notification.user_id === null ||
                            notification.user_id === ''
                        "
                    >
                        <h2>Mine</h2>
                        <span>System Notification</span>
                    </ng-container>

                    <h2 *ngIf="notification.user_id">
                        {{ notification.user_full_name }}
                    </h2>
                    <span *ngIf="notification.user_id">{{
                        notification.user_role
                    }}</span>
                </div>

                <div style="width: 240px">
                    <div
                        class="step_label"
                        [ngStyle]="{
                            'background-color': getStepColor(notification),
                        }"
                        *ngIf="notification.step"
                    >
                        <img
                            *ngIf="notification.step"
                            src="assets/icons/steps/{{
                                fix_step_name(notification.step)
                            }}.svg"
                        />
                        <h2>
                            Update-{{ notification.batch_id }} :
                            {{ capitalize(notification.step) }}
                        </h2>
                        <img src="assets/icons/step_tooltip.svg" />
                    </div>
                </div>
                <p class="date">{{ notification.display_date }}</p>
                <p class="message">{{ notification.message }}</p>
            </div>
            <div id="notifications_paginator">
                <p
                    (click)="this.notificationsNavigate('previous')"
                    *ngIf="this.canNavigate('previous')"
                >
                    <img
                        src="assets/icons/menu/paginator/chevron_left_enabled.svg"
                    />
                    Previous
                </p>
                <p
                    (click)="this.notificationsNavigate('next')"
                    *ngIf="this.canNavigate('next')"
                >
                    Next
                    <img
                        src="assets/icons/menu/paginator/chevron_right_enabled.svg"
                    />
                </p>
            </div>
        </div>
    </div>
</div>
