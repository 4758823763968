<div style="margin-top: 100px">
    <form>
        <label for="">Scope of Subscription*</label>
        <app-mine-dropdown>
            [options]="scopes_of_subscription" [enable_other]="true"
            [enbale_other_value]="true" [other_list_label]="'Partial'"
            [other_label]="'Defined Markets'" [other_placeholder]="'Type in
            markets'" placeholder="Select scope of subscription"
            formControlName="scope_of_subscription"
        </app-mine-dropdown>
    </form>
</div>
