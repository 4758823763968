import { Component, EventEmitter, Input, Output } from "@angular/core";
import { TreeNode } from "primeng/api";
import {
  dataGroup,
  Column,
  isDataGroup,
  wrapArrayInTreeNode,
} from "./data-group";

@Component({
  selector: "app-mine-table",
  templateUrl: "./mine-table.component.html",
  styleUrls: ["./mine-table.component.scss"],
})
export class MineTableComponent {
  @Input() data: any[] | undefined = undefined;
  @Input() treeData: TreeNode<any>[] | undefined = undefined;

  @Input() files: TreeNode<any>[] | undefined;
  @Input() headers: any[] = [];

  @Input() selectionPredicate: ((rowData: any) => boolean) | undefined =
    undefined;

  @Output() rowClicked = new EventEmitter<any>();
  @Output() rowMouseEnter = new EventEmitter<any>();
  @Output() rowMouseLeave = new EventEmitter<any>();

  @Input()
  useIdForGroup = true;

  isTreeData() {
    return this.treeData != undefined;
  }

  getArrayData(): any[] {
    if (Array.isArray(this.data)) return this.data;
    else return [];
  }

  isArrayData() {
    return this.data !== undefined;
  }

  jsonify(obj: any) {
    return JSON.stringify(obj);
  }

  getColumns(): Column[] {
    return this.headers;
  }

  isEmpty() {
    return this.data === undefined || this.data.length === 0;
  }

  internalRowClicked(row: any) {
    this.rowClicked.emit(row);
  }

  internalRowMouseEnter(row: any) {
    this.rowMouseEnter.emit(row);
  }

  internalRowMouseLeave() {
    this.rowMouseLeave.emit();
  }
}
