import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
} from "@angular/core";
import {
  stepInformation,
  stepsInformation,
  stepState,
} from "./stepsInformation";
import { stages } from "src/app/home-component/stages";
import { first } from "lodash";

//@TODO remove when API starts returning this
const duration = 50;

@Component({
  selector: "app-steps-component",
  templateUrl: "./steps-component.component.html",
  styleUrls: ["./steps-component.component.scss"],
})
export class StepsComponentComponent implements OnChanges {
  currentSteps = Array(7);
  stepState = stepState;

  @Input() currentStep: string | undefined;
  @Output() currentStepChange = new EventEmitter();

  @Input() isClickable = false;
  @Input() isUpdateCenter = false;

  @Input() stepsInfo: stepsInformation | undefined = {
    collection: { duration: duration, state: stepState.completed },
    cleaning: { duration: duration, state: stepState.completed },
    consolidation: { duration: duration, state: stepState.completed },
    referencing: { duration: duration, state: stepState.completed },
    recoding: { duration: duration, state: stepState.completed },
    categorization: { duration: duration, state: stepState.completed },
    transcoding: { duration: duration, state: stepState.completed },
  };
  displaySteps: any;

  ngOnInit() {
    this.displaySteps = this.stepsDisplay();

    setInterval(() => (this.displaySteps = this.stepsDisplay()), 1000);
  }

  allStepsAreCompleted() {
    if (!this.stepsInfo) return false;
    return Object.values(this.stepsInfo)
      .map(
        (stepInfo: stepInformation) => stepInfo.state === stepState.completed,
      )
      .every((state: boolean) => state);
  }

  jsonify(obj: any) {
    return JSON.stringify(obj);
  }

  ngOnChanges() {
    this.displaySteps = this.stepsDisplay();
  }

  allStepsSelected() {
    return this.currentStep === "all_steps";
  }

  stepsDisplay() {
    console.log(this.currentStep);
    if (!this.stepsInfo) return [];
    console.log(this.stepsInfo);
    let displaySteps = [] as any;

    for (const [step, stepInfo] of Object.entries(this.stepsInfo)) {
      displaySteps.push({
        step: step,
        name: this.capitalize(step),
        state: stepInfo.state,
        display_state: this.getStepStateLabel(stepInfo.state),
        status_color: this.getStepStateColor(stepInfo.state),
        line_color: this.getStepStateLineColor(stepInfo.state),
        dot_icon: this.getStepStateDotIcon(stepInfo.state),
        debug: this.currentStep !== undefined && this.currentStep !== step,
        duration: step !== "collection" ? stepInfo.duration : "",
        transparent_line: !this.allStepsSelected()
          ? this.currentStep && this.currentStep !== step
          : false,
      });
    }
    displaySteps = [...displaySteps];

    console.log(displaySteps);
    return displaySteps;
  }

  getStepStateDotIcon(state: stepState) {
    const icons: { [key in stepState]: string } = {
      [stepState.completed]: "completed.svg",
      [stepState.in_progress]: "in_progress.svg",
      [stepState.pending]: "pending.svg",
    };

    return icons[state];
  }

  getStepStateLineColor(state: stepState) {
    const colors: { [key in stepState]: string } = {
      [stepState.completed]: "#35BA55",
      [stepState.in_progress]: "#0036DA",
      [stepState.pending]: "#EBECED",
    };

    return colors[state];
  }

  getStepStateColor(state: stepState) {
    const colors: { [key in stepState]: string } = {
      [stepState.completed]: "#30A94F",
      [stepState.in_progress]: "#0036DA",
      [stepState.pending]: "#8E9397",
    };

    return colors[state];
  }

  getStepStateLabel(state: stepState) {
    switch (state) {
      case stepState.completed:
        return "Completed";
      case stepState.in_progress:
        return "In progress";
      case stepState.pending:
        return "Pending";
      default:
        return "";
    }
  }

  capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  formatDuration(duration: number) {
    const units = {
      days: "d",
      hours: "h",
      minutes: "m",
      seconds: "s",
    } as Record<string, string>;
    const formatted_duration = convertMillisecondsToTime(duration);
    const firstNoneZeroUnit = Object.keys(
      formatted_duration as Record<string, number>,
    ).find(
      (unit: string) =>
        (formatted_duration as Record<string, number>)[unit] !== 0,
    );

    if (!firstNoneZeroUnit) return;

    if (firstNoneZeroUnit === "seconds") {
      return `${this.addZero(formatted_duration.seconds)}s`;
    } else {
      let nice_duration = formatted_duration as Record<string, number>;
      let index = Object.keys(units).findIndex(
        (unit: string) => unit === firstNoneZeroUnit,
      );

      //Assume index is always positive
      let secondUnit = Object.keys(units)[index + 1];

      if (nice_duration[secondUnit] !== 0)
        return `${nice_duration[firstNoneZeroUnit]}${
          units[firstNoneZeroUnit]
        } ${this.addZero(nice_duration[secondUnit])}${units[secondUnit]}`;
      else
        return `${nice_duration[firstNoneZeroUnit]}${units[firstNoneZeroUnit]}`;
    }
  }

  isStepDisabled(step: string) {
    const state = (this.stepsInfo as any)[step].state;
    console.log(state);
    return state === stepState.pending || state === stepState.in_progress;
  }

  addZero(number: number) {
    return number < 10 ? "0" + number : number;
  }

  stepClicked(step: any) {
    console.log(step);
    if (this.isClickable && !this.isStepDisabled(step)) {
      this.currentStep = step;
      this.currentStepChange.emit(step);
    }
  }
}

function convertMillisecondsToTime(milliseconds: number): {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
} {
  const seconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  return {
    days: days,
    hours: hours % 24,
    minutes: minutes % 60,
    seconds: seconds % 60,
  };
}
