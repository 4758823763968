<div id="wrapper">
    <div class="page_header" *ngIf="this.loadingIsComplete">
        <app-sub-menu [displayName]="getSubMenuDisplayName()"></app-sub-menu>
        <h1>{{ getTitle() }}</h1>
        <div id="filters">
            <app-filters
                [filters]="this.filterComponentValues()"
                (filtersChanged)="this.selectedFilterChanged($event)"
            ></app-filters>
            <p-multiSelect
                class="borderless without_border update_status"
                [options]="data_status_options"
                [group]="true"
                [placeholder]="'Update Status'"
            >
                <ng-template let-group pTemplate="group">
                    <span *ngIf="group.label" class="group_label">{{
                        group.label
                    }}</span>
                </ng-template>
                <ng-template pTemplate="selectedItems">
                    <div>Update Status</div>
                </ng-template>
            </p-multiSelect>
        </div>
    </div>
    <div id="updates" *ngIf="this.loadingIsComplete">
        <div
            class="no_updates_message"
            *ngIf="
                this.updates.length === 0 &&
                (this.selected_status === 'in_progress' ||
                    this.selected_status === 'pending')
            "
        >
            {{
                this.selected_status === "in_progress"
                    ? "No Active Updates"
                    : "No Pending Updates"
            }}
        </div>
        <app-mine-accordion
            [template]="update_template"
            [update]="update"
            [expanded]="first"
            *ngFor="
                let update of updates
                    | updatesCountryFilter
                        : this.filters['countries'].selected_values
                    | updatesDataTypeFilter
                        : this.filters['data_types'].selected_values
                    | updatesChannelFilter
                        : this.filters['channels'].selected_values
                    | updatesGranularityFilter
                        : this.filters['granularities'].selected_values
                    | updatesFrequencyFilter
                        : this.filters['frequencies'].selected_values;
                let first = first
            "
        >
            <ng-template #update_template>
                <div>
                    <div class="details_header">
                        <h2>
                            {{ this.getStatusLabel(update.data.currentstatus) }}
                        </h2>
                        <div
                            (click)="this.proceed(update)"
                            id="start_button"
                            [ngClass]="{
                                disabled: !this.canYouProceed(update),
                            }"
                            *ngIf="this.canYouProceed(update)"
                        >
                            Proceed
                        </div>

                        <div id="steps-dropdown">
                            <p-dropdown
                                [options]="update.validSteps"
                                optionDisabled="isDisabled"
                                [group]="true"
                                width="70px"
                                [(ngModel)]="
                                    this.updates_current_steps[update.batch_id]
                                "
                            >
                                <ng-template pTemplate="selectedItem">
                                    <div class="selected_step">
                                        <img
                                            *ngIf="
                                                this.updates_current_steps[
                                                    update.batch_id
                                                ] !== 'all_steps'
                                            "
                                            src="assets/icons/steps/black/{{
                                                this.updates_current_steps[
                                                    update.batch_id
                                                ]
                                            }}.svg"
                                        />
                                        <p>
                                            {{
                                                this.getStepDropdownLabel(
                                                    this.updates_current_steps[
                                                        update.batch_id
                                                    ]
                                                )
                                            }}
                                        </p>
                                    </div>
                                </ng-template>

                                <ng-template let-group pTemplate="group">
                                    <div class="items_group_name">
                                        <span *ngIf="group.label">{{
                                            group.label
                                        }}</span>
                                    </div>
                                </ng-template>

                                <ng-template let-step pTemplate="item">
                                    <div
                                        class="stage_list_item"
                                        [ngClass]="{
                                            all_steps_item: step.isAll,
                                        }"
                                    >
                                        <img
                                            *ngIf="!step.isAll"
                                            src="assets/icons/steps/black/{{
                                                step.value
                                            }}.svg"
                                        />
                                        <p>
                                            {{ step.label }}
                                        </p>
                                    </div>
                                </ng-template>
                            </p-dropdown>
                        </div>
                    </div>
                    <app-steps-component
                        [isUpdateCenter]="true"
                        [stepsInfo]="this.updatesStepsData[update.batch_id]"
                        [isClickable]="true"
                        [currentStep]="
                            this.updates_current_steps[update.batch_id]
                        "
                        (currentStepChange)="setNewStep($event, update)"
                    ></app-steps-component>
                    <div
                        class="update_statistic"
                        *ngIf="!this.isPendingUpdate(update)"
                    >
                        <div class="update_statistic_header">
                            <h2>Update Statistic</h2>
                        </div>

                        <div class="update_statistic_content">
                            <app-mine-table
                                [data]="this.summary_tables[update.batch_id]"
                                [headers]="this.summary_table_headers"
                                *ngIf="
                                    this.updates_current_steps[
                                        update.batch_id
                                    ] === 'all_steps'
                                "
                            >
                            </app-mine-table>

                            <div
                                class="sources_section"
                                *ngIf="this.displayStepKPIs(update)"
                            >
                                <app-mine-table
                                    [headers]="this.stepKPIsHeaders"
                                    (rowClicked)="
                                        this.attributeSelected(
                                            $event,
                                            this.updates_current_steps[
                                                update.batch_id
                                            ],
                                            update
                                        )
                                    "
                                    [treeData]="
                                        this.stepKPIs[
                                            this.updates_current_steps[
                                                update.batch_id
                                            ]
                                        ][update.batch_id]
                                    "
                                    *ngIf="this.displayStepKPIs(update)"
                                >
                                </app-mine-table>
                            </div>
                            <p-accordion
                                *ngIf="
                                    this.stepKPIs && this.stepKPIs['recoding']
                                "
                            >
                                <p-accordionTab
                                    header="Sales information"
                                    *ngIf="this.displaySalesInfromation(update)"
                                >
                                    <app-mine-table
                                        [headers]="this.salesInformationHeaders"
                                        [treeData]="
                                            this.updates_sales_information[
                                                update.batch_id.toString()
                                            ]
                                        "
                                        *ngIf="
                                            this.displaySalesInfromation(update)
                                        "
                                    >
                                    </app-mine-table>
                                </p-accordionTab>
                                <p-accordionTab
                                    header="Data Delivery"
                                    *ngIf="this.displayDataDelivery(update)"
                                >
                                    <app-mine-table
                                        [headers]="this.data_delivery_headers"
                                        [data]="[]"
                                        *ngIf="this.displayDataDelivery(update)"
                                    >
                                    </app-mine-table>
                                </p-accordionTab>
                                <p-accordionTab
                                    header="Delays"
                                    *ngIf="this.displayDataDelivery(update)"
                                >
                                    <div id="delays_chart_wrapper">
                                        <app-bars-chart
                                            [delays_data]="
                                                this.sources_delays[
                                                    update.batch_id
                                                ]
                                            "
                                        ></app-bars-chart>
                                    </div>
                                </p-accordionTab>
                            </p-accordion>
                            <div id="attributes_chart_container">
                                <app-attributes-charts
                                    *ngIf="this.displayStepKPIs(update)"
                                    [current_records_data]="
                                        this.attribute_charts_values[
                                            'current_records'
                                        ]
                                    "
                                    [unique_values_data]="
                                        this.attribute_charts_values[
                                            'unique_values'
                                        ]
                                    "
                                    [percent_blanks_data]="
                                        this.attribute_charts_values[
                                            'percent_blanks'
                                        ]
                                    "
                                    [field_name]="
                                        this.getCurrentSelectedField()
                                    "
                                ></app-attributes-charts>
                            </div>
                        </div>
                    </div>
                    <div
                        class="update_sources"
                        [ngClass]="{
                            add_margin: this.isPendingUpdate(update.batch_id),
                        }"
                    >
                        <source-card
                            [id]="update.data.id_source"
                            [panel_name]="update.data.panel_x"
                            [state]="
                                this.currentStatusConverter(
                                    update.data.currentstatus
                                )
                            "
                            [quality]="update.data.quality"
                            [end_period]="
                                timeConverter(update.data.end_period, false)
                            "
                            [next_update]="
                                timeConverter(update.data.next_update)
                            "
                            [person_name]="update.data.contact_owner_name"
                            [country_code]="update.data.country_code"
                            [data_vendor]="update.data.data_vendor"
                            [data_type]="update.data.data_type"
                            [granularity]="update.data.granularity"
                            [update_frequency]="update.data.update_frequency"
                        ></source-card>
                    </div>
                </div>
            </ng-template>
        </app-mine-accordion>
    </div>
    <div id="spinner_wrapper" *ngIf="!this.loadingIsComplete">
        <p-progressSpinner />
    </div>
</div>
