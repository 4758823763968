<div id="wrapper">
    <div id="steps">
        <div
            class="step"
            *ngFor="let step of this.displaySteps; index as i; last as last"
            (click)="this.stepClicked(step.step)"
        >
            <div class="shapes">
                <img
                    src="assets/icons/steps/dot_icons/{{ step.dot_icon }}"
                    [ngClass]="{ transparent_shape: step.transparent_line }"
                />
                <svg
                    height="2"
                    width="100%"
                    *ngIf="!last"
                    [ngClass]="{ transparent_shape: step.transparent_line }"
                >
                    <rect
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="2"
                        rx="1"
                        [attr.fill]="step.line_color"
                    />
                </svg>
            </div>
            <ng-container *ngIf="this.stepsInfo"> </ng-container>
            <span>
                STEP {{ i + 1 }}
                <img
                    *ngIf="
                        step.state === stepState.completed &&
                        step.step !== 'collection'
                    "
                    class="step_dot"
                    src="/assets/icons/step_dot.svg"
                />
                <span
                    *ngIf="
                        step.state === stepState.completed ||
                        step.state === stepState.in_progress
                    "
                >
                    {{
                        step.step !== "collection"
                            ? this.formatDuration(step.duration)
                            : ""
                    }}
                </span>
            </span>
            <h3 class="label">
                {{ step.name }}
            </h3>
            <h3 class="status" [ngStyle]="{ color: step.status_color }">
                {{ step.display_state }}
            </h3>
        </div>
    </div>
</div>
