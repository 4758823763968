import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ConfirmationService } from "primeng/api";
import { BRMServiceService as BRMService } from "../core/services/brmservice.service";
import { UndoQueue } from "../brm/undoQueue";
import { AccountService } from "../core/services/account.service";

@Component({
  selector: "app-confirm-changes-dialog",
  templateUrl: "./confirm-changes-dialog.component.html",
  styleUrls: ["./confirm-changes-dialog.component.scss"],
})
export class ConfirmChangesDialogComponent implements OnInit {
  @Input() changeLog: UndoQueue | undefined;
  @Input() tableName = "";
  @Output() windowClosed = new EventEmitter();
  @Output() changesConfirmed = new EventEmitter();

  activity_display_limit = 5 as number | undefined;
  account_information: any | undefined;

  isConfirmed = false;

  constructor(
    private confirmationSerivce: ConfirmationService,
    private brmService: BRMService,
    private accountService: AccountService,
  ) {}

  ngOnInit(): void {
    this.accountService.getMyAccount().subscribe((account: any) => {
      this.account_information = account;
    });
  }

  confirmChanges() {
    if (this.tableName)
      this.submitChanges().subscribe(() => (this.isConfirmed = true));
  }

  toggleActivityLimit() {
    if (this.activity_display_limit) this.activity_display_limit = undefined;
    else this.activity_display_limit = 2;
  }

  seeMoreCaption() {
    return this.activity_display_limit ? "See more" : "See less";
  }

  numberOfRecords() {
    return this.changeLog?.getUniqueChangedRecords();
  }

  submitChanges() {
    return this.brmService.commitChanges(
      this.changeLog?.convertChangesToBackendFormat(this.tableName),
    );
  }

  getChangeActivity() {
    if (!this.changeLog) return [];
    return this.changeLog.convertChangesToActivity();
  }

  closeDialog() {
    this.windowClosed.emit();
    this.changesConfirmed.emit();
    this.confirmationSerivce.close();
  }
}
